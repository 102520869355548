<template>
  <div class="flex flex-col space-y-4">
    <div class="text-xl font-bold">{{ detail.title }}</div>
    <img
      class="rounded-lg shadow-xl object-fill object-center w-full"
      :src="detail.imageUrl">
    <div
      v-if="date"
      class="text-base text-gray-400 text-right">{{ date }}</div>
    <div class="text-base text-gray-800" v-html="detail.contents"></div>
    <div class="flex flex-col space-y-4">
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-1/6">Komisi</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ information.commission }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-1/6">{{ information.serviceType }}</div>
        <div class="text-base text-gray-800">:</div>
        <div @click="handleService" class="text-base text-blue-500 underline cursor-pointer">Mengikuti Persekutuan</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
import { mapState, mapActions } from 'vuex'
import { goUrl } from '@/utils/browser'
import { gunsaImpression } from '@/utils/trackers'

export default {
  name: 'DetailFellowship',
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('fellowship', ['detail']),
    fellowshipId () {
      return this.$route.params.id
    },
    date () {
      return this.detail?.date && dateFormater(this.detail.date)
    },
    information () {
      return this.detail?.information ?? {}
    }
  },
  methods: {
    ...mapActions('fellowship', ['fetchDetail', 'resetDetail']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    initPage () {
      this.showLoading()
      this.resetDetail()
      this.fetchDetail({
        id: this.fellowshipId,
        success: this.handleSuccess,
        fail: this.handleFail
      })
    },
    handleSuccess () {
      this.hideLoading()
      gunsaImpression({
        page: 'Persekutuan',
        title: this.detail.title,
        value: `www.gkigunsa.com/persekutuan/${this.detail.id}`
      })
    },
    handleFail () {
      this.hideLoading()
      this.$router.go(-1)
    },
    handleService () {
      goUrl(this.information.serviceValue)
    }
  }
}
</script>

<style scoped>

</style>
